
import { mapState } from 'vuex'

/**
 * Navbar component
 */
export default {
  props: {
    isWhiteNavbar: {
      type: Boolean
    },
    isIcons: {
      type: Boolean
    }
  },
  data () {
    return {
      isCondensed: false,
      logo_dark: true,
      nav_sticky: false,
      logo_light_src: require('@/assets/images/logo-text-white.svg'),
      logo_dark_src: require('@/assets/images/logo-text.svg')
    }
  },

  computed: {
    ...mapState({
      menu_list: state => state.menu.data
    }),
    navLight () {
      if (this.$route && this.$route.name) {
        return this.$route.name.startsWith('index')
      }
      return false
    },
    isClient () {
      return process.client
    },
    isIndex () {
      if (this.$route && this.$route.name) {
        return this.$route.name.startsWith('index')
      }
      return false;
    },
    isSmallScreen () {
      return window.innerWidth <= 991
    },
    availableLocales () {
      return this.$i18n.locales
    }
  },

  watch: {
    $route () {
      const isIndexPage = this.$route && this.$route.name ? this.$route.name.startsWith('index') : false
      this.logo_dark = !isIndexPage || (isIndexPage && this.isSmallScreen)
    }
  },

  beforeMount () {
    window.addEventListener('resize', this.onWindowResize)
    window.addEventListener('scroll', this.onWindowScroll)
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.onWindowResize)
    window.removeEventListener('scroll', this.onWindowScroll)
  },

  mounted () {
    // this.logo_dark = !this.isIndex || (this.isIndex && this.isSmallScreen)
    if (this.isClient) {
      this.updateHeader()
    }
  },

  methods: {
    toggleMenu () {
      this.isCondensed = !this.isCondensed
      if (this.isCondensed) {
        document.getElementById('navigation').style.display = 'block'
      } else {
        document.getElementById('navigation').style.display = 'none'
      }
    },

    onMenuClick (event) {
      event.preventDefault()
      const nextEl = event.target.nextSibling.nextSibling

      if (nextEl && !nextEl.classList.contains('open')) {
        const parentEl = event.target.parentNode
        if (parentEl) {
          parentEl.classList.remove('open')
        }
        nextEl.classList.add('open')
      } else if (nextEl) {
        nextEl.classList.remove('open')
      }
      return false
    },

    onWindowScroll (event) {
      this.updateHeader()
    },
    updateHeader() {
      if (window.scrollY > 50) {
        this.nav_sticky = true
        if (this.isIndex) {
          this.logo_dark = true
        }
      } else {
        const smallScreen = window.innerWidth <= 991
        this.nav_sticky = false
        this.logo_dark = !this.isIndex || (this.isIndex && smallScreen)
      }
    },
    onWindowResize (event) {
      this.updateHeader()
    },
    changeLocale(locale) {
      this.$i18n.setLocale(locale.code)
    }
  }
}
